import React, { Fragment, useEffect } from 'react';
import Quote from './blogSections/Quote';
import MarkdownText from './blogSections/MarkdownText';
import WideImage from './blogSections/WideImage';
import DottedImage from './blogSections/DottedImage';
import PointedList from './blogSections/PointedList';
import Image from './blogSections/Image';

let textCont = 0;

export default function FlexibleContent({ sections, blogResource }) {
  useEffect(() => {
    textCont = 0;
  }, []);

  const chooseSection = (section) => {
    switch (section.type) {
      case 'wideImage':
        return <WideImage src={section.src} alt={section.alt} />;
      case 'quoteSection':
        return <Quote text={section.text} />;
      case 'markdownText':
        textCont++;
        return (
          <MarkdownText
            text={section.text}
            aside={
              textCont === 1 ? 'inbox' : textCont === 2 ? 'download' : null
            }
            blogResource={blogResource}
          />
        );
      case 'dottedImage':
        return <DottedImage src={section.src} alt={section.alt} />;
      case 'image':
        return <Image src={section.src} alt={section.alt} />;
      case 'pointedList':
        return <PointedList items={section.items} />;
      default:
        return <Fragment />;
    }
  };

  return (
    <Fragment>
      {sections.map((section, index) => (
        <Fragment key={section.type + '_' + index}>
          {chooseSection(section)}
        </Fragment>
      ))}
    </Fragment>
  );
}
