import React, { useEffect, useRef } from 'react';

import { useCompleteOnScreen } from '../../../utils/hooks';

import { AnimatedDots } from '../../Dots';
import './index.css';
import zigzag from '../../../img/zigzag.svg';
import mail from '../../../img/mail-send.svg';
import dots from '../../../img/case-study-dots-1.svg';

export default ({ blog }) => {
  const ref = useRef();

  const completeOnScreen = useCompleteOnScreen(ref);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '7247359',
          formId: '0a1fe25d-51ec-4454-b821-787c4fcfe411',
          target: '#hubspotForm'
        });
      }
    });
  }, []);

  return (
    <section
      ref={ref}
      className={`${blog ? 'blog-inbox' : 'blogetry-inbox'} ${
        !blog && 'secondary-padding'
      }`}
    >
      <img
        className="zigzag"
        src={zigzag}
        alt="Zigzag"
      />
      <img
        className="mail"
        src={mail}
        alt="Mail"
      />
      <img
        className="left-dots"
        src={dots}
        alt="Dots"
      />
      <AnimatedDots completeOnScreen={completeOnScreen} />
      <div className="content">
        <div className="left">
          <p className="c-title">Your inbox deserves the best!</p>
          <p className="sub-title">
            Sign up for design, dev, & product trends.
          </p>
        </div>
        <div className="right">
          <div id="hubspotForm"></div>
        </div>
      </div>
    </section>
  );
};
