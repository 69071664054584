import React, { useRef, useEffect, useState } from 'react';
import { useCompleteOnScreen } from '../../../utils/hooks';

import Button from '../../Button/';
import BlogCard from '../../BlogCard/';
import { AnimatedDots } from '../../Dots';
import './index.css';

export default ({ first, second }) => {
  const ref = useRef();
  const completeOnScreen = useCompleteOnScreen(ref);
  const [subscribeToBlog, setSubscribeToBlog] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '7247359',
          formId: '0a1fe25d-51ec-4454-b821-787c4fcfe411',
          target: '#hubspotFormSubscribe'
        });
      }
    });
  }, []);

  return (
    <section ref={ref} className="blog-recommended">
      <div className="root-padding">
        <AnimatedDots completeOnScreen={completeOnScreen} />
        <div className="top">
          <h2>If you liked that, try these!</h2>
          <Button className={`subscribe-buttton ${subscribeToBlog ? 'hidden' : '' }`} 
            text="subscribe to the blog" 
            onClick={() => {
              setSubscribeToBlog(true);
            }} 
          />
          <div id="hubspotFormSubscribe" className={`${subscribeToBlog ? '' : 'hidden' }`}></div>
        </div>
      </div>
      <div className="bottom">
        <BlogCard item={first} />
        <BlogCard item={second} />
        <div className="background" />
      </div>
    </section>
  );
};
