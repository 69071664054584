import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

import './index.css';

export default ({ item, className }) => {
  const [formatDate, setFormatDate] = useState(new Date().toDateString());

  useEffect(() => {
    const dateObj = new Date(item.date);
    const month = dateObj.getUTCMonth() + 1;
    const day = dateObj.getUTCDate();
    const year = dateObj.getUTCFullYear();
    setFormatDate(`${month}.${day}.${year}`);
  }, []);

  return (
    <div className={`blog-card ${className ? className : ''}`}>
      <Link to={item.slug}>
        <div className="image-container">
          <Img
            className="blog-image"
            fluid={item.coverImage.src.childImageSharp.fluid}
            alt={item.coverImage.alt}
          />
        </div>
        <div className="center">
          <p className="category">{item.category}</p>
          <p className="date">{formatDate}</p>
        </div>
        <p className="c-title">{item.title}</p>
        {item.readTime && <p className="read-time">{item.readTime}</p>}
      </Link>
    </div>
  );
};
