import React, { Fragment, useEffect, useState } from 'react';
import Img from 'gatsby-image';

import './index.css';
import facebook from '../../../img/facebook.svg';
import twitter from '../../../img/twitter.svg';
import linkedIn from '../../../img/linkedin.svg';
import zigzag from '../../../img/zigzag.svg';

export default ({
  summaryText,
  coverImage,
  title,
  category,
  date,
  location,
  author
}) => {
  const [formatDate, setFormatDate] = useState(new Date().toISOString());

  useEffect(() => {
    const dateObj = new Date(date);
    const month = dateObj.getUTCMonth() + 1;
    const day = dateObj.getUTCDate();
    const year = dateObj.getUTCFullYear();
    setFormatDate(`${month}.${day}.${year}`);
  }, []);

  const mediaContainer = () => (
    <div className="social-media-container">
      <p>Share</p>
      <a
        href={`https://www.facebook.com/sharer/sharer.php?u=${location.href}`}
        target="_blank"
        rel="noreferrer"
      >
        <img src={facebook} alt="Facebook" />
      </a>
      <a
        href={`https://twitter.com/intent/tweet?url=${location.href}`}
        target="_blank"
        rel="noreferrer"
      >
        <img src={twitter} alt="Twitter" />
      </a>
      <a
        href={`https://www.linkedin.com/shareArticle?mini=true&url=${location.href}`}
        target="_blank"
        rel="noreferrer"
      >
        <img src={linkedIn} alt="Linkedin" />
      </a>
    </div>
  );

  return (
    <Fragment>
      <section className="blog-hero">
        <div className="left">
          <h1>{title}</h1>
          <p className="category">{category}</p>
          <div>
            <p className="date">{formatDate}</p>
            <div className="media-mobile">{mediaContainer()}</div>
          </div>
          {author && <div>
            <p className="date">Author: {author}</p>
          </div>}
        </div>
        <div className="right">
          <img
            className="zigzag"
            src={zigzag}
            alt="Zigzag"
          />
          <div className="image-container">
            <Img
              fluid={coverImage.src.childImageSharp.fluid}
              alt={coverImage.alt}
            />
          </div>
          <div className="media-desktop">{mediaContainer()}</div>
        </div>
      </section>
      {summaryText && (
        <section className="secondary-padding  blog-summary">
          <p>{summaryText}</p>
        </section>
      )}
    </Fragment>
  );
};
