import React from 'react';

import ArrowButtonText from '../../ArrowButtonText';
import './index.css';

export default ({ blogResource }) => {
  return (
    <section className="download-box">
      <a href={blogResource.url} target="_blank" rel="noreferrer">
        <p className="c-title">{blogResource.title}</p>
        <ArrowButtonText text="Download" />
      </a>
    </section>
  );
};
