import React from 'react';
import Marked from '../../Marked';
import Inbox from '../../blogetrySections/InboxSection';
import Download from '../DownloadBox';

import './index.css';

export default ({ text, aside, blogResource }) => {
  return (
    <section className="blog-markdown">
      {aside === 'inbox' ? (
        <Inbox blog />
      ) : aside === 'download' && blogResource ? (
        <Download blogResource={blogResource} />
      ) : null}
      <div className="secondary-padding">
        <Marked className="text blog-left" content={text} />
      </div>
    </section>
  );
};
