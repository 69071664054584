import React from 'react';

import ArrowButton from '../ArrowButton';
import './index.css';

export default ({ text }) => {
  return (
    <div className="c-arrow-button-text">
      <p>{text}</p>
      <ArrowButton />
    </div>
  );
};
