import React from 'react';

import './index.css';

export default ({ items }) => {
  return (
    <section className="secondary-padding blog-pointed-list">
      <ul className=" blog-left">
        {items.map((item) => (
          <li>{item}</li>
        ))}
      </ul>
    </section>
  );
};
