import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

// Sections
import Hero from '../../components/blogSections/Hero';
import Recommended from '../../components/blogSections/Recommended';
import FlexibleContent from '../../components/BlogFlexibleContent';

import './index.css';

const BlogPageTemplate = ({
  siteUrl,
  canonicalUrl,
  description,
  openGraphImage,
  heroSummary,
  coverImage,
  title,
  category,
  date,
  sections,
  location,
  secondRecommended,
  firstRecommended,
  blogResource,
  author
}) => {
  return (
    <>
      <Helmet>
        <title>{title} | Devetry</title>
        <meta property="og:title" content={`${title} | Devetry`} />
        <meta property="og:type" content="article" />
        {!!description && (
          <meta property="og:description" content={description} />
        )}
        {!!canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
        {!!openGraphImage && (
          <meta
            property="og:image"
            content={`${siteUrl}/img/${openGraphImage.relativePath}`}
          />
        )}
      </Helmet>
      <main className="blog root-container">
        <Hero
          coverImage={coverImage}
          summaryText={heroSummary}
          title={title}
          category={category}
          date={date}
          location={location}
          author={author}
        />
        <FlexibleContent sections={sections} blogResource={blogResource} />
        {firstRecommended && secondRecommended && (
          <Recommended first={firstRecommended} second={secondRecommended} />
        )}
      </main>
    </>
  );
};

const BlogPage = ({ data, location }) => {
  const { frontmatter, fields } = data.markdownRemark;
  const blogResource = fields.blogResource
    ? {
        ...fields.blogResource.fields,
        ...fields.blogResource.frontmatter
      }
    : null;
  
  const firstRecommended = fields.firstRecommended 
    ? {
        ...fields.firstRecommended.frontmatter, 
        slug: fields.firstRecommended.fields.slug 
      }
    : null;

  const secondRecommended = fields.secondRecommended 
      ? {
          ...fields.secondRecommended.frontmatter,
          slug: fields.secondRecommended.fields.slug
        }
      : null;

  return (
    <BlogPageTemplate
      siteUrl={data.site.siteMetadata.siteUrl}
      canonicalUrl={frontmatter.canonicalUrl}
      description={frontmatter.description}
      openGraphImage={frontmatter.openGraphImage}
      coverImage={frontmatter.coverImage}
      heroSummary={frontmatter.heroSummary}
      title={frontmatter.title}
      category={frontmatter.category}
      date={frontmatter.date}
      sections={frontmatter.sections}
      location={location}
      firstRecommended={firstRecommended}
      secondRecommended={secondRecommended}
      blogResource={blogResource}
      author={frontmatter.author}
    />
  );
};

BlogPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default BlogPage;

export const pageQuery = graphql`
  fragment Image on Sections {
    src {
      childImageSharp {
        fluid(quality: 100, maxWidth: 612) {
          ...GatsbyImageSharpFluid
        }
      }
      publicURL
    }
    alt
  }

  fragment QuoteSection on Sections {
    text
  }

  fragment PointedList on Sections {
    items
  }

  query BlogByID($id: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(id: { eq: $id }) {
      fields {
        firstRecommended {
          fields {
            slug
          }
          frontmatter {
            title
            date
            category
            coverImage {
              src {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
            }
          }
        }
        secondRecommended {
          fields {
            slug
          }
          frontmatter {
            title
            date
            category
            coverImage {
              src {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
            }
          }
        }
        blogResource {
          fields {
            slug
          }
          frontmatter {
            title
            url
          }
        }
      }
      frontmatter {
        templateKey
        title
        canonicalUrl
        description
        openGraphImage {
          relativePath
        }
        date
        coverImage {
          src {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
        }
        category
        heroSummary
        sections {
          type
          ...Image
          ...QuoteSection
          ...PointedList
        }
        author
      }
    }
  }
`;
