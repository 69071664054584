import React from 'react';
import Img from 'gatsby-image';

import './index.css';

export default ({ src, alt }) => {
  return (
    <section className="blog-wide-image">
      {!!src.childImageSharp ?
        <Img className="work-image" fluid={src.childImageSharp.fluid} alt={alt} />
        : <img href={src.publicURL} alt={alt}></img>}
    </section>
  );
};
