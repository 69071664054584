import React from 'react';
import Img from 'gatsby-image';

import './index.css';

export default ({ src, alt }) => {
  return (
    <section className="blog-image secondary-padding padded-img">
      {!!src.childImageSharp ?
        <Img fluid={src.childImageSharp.fluid} alt={alt} />
        : <img src={src.publicURL} alt={alt}></img>}
    </section>
  );
};
