import React from 'react';
import Img from 'gatsby-image';

import './index.css';

export default ({ src, alt }) => {
  return (
    <section className="blog-dotted-image secondary-padding">
    {!!src.childImageSharp ?
      <Img
        className="work-image blog-left"
        fluid={src.childImageSharp.fluid}
        alt={alt}
      />
      : <img src={src.publicURL} alt={alt}></img>}
      <span />
    </section>
  );
};
